<template>
	<div class="popup active">
		<div class="notice_popup">
			<div class="close">
				<img src="/images/close.png" alt="닫기" @click="$emit('close')"/>
			</div>
			<div class="tit">하이프로 뉴스 등록</div>
			<div class="member">입력하신 내용으로 등록하시겠습니까?</div>
			<div class="select-btn">
				<div class="btn" @click="$emit('close')">취소</div>
				<div class="btn" @click="$emit('close', true)">예</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {

}
</script>

<style>

</style>